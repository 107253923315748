<template>
  <div class="flex-grow flex-shrink min-w-0 p-6 overflow-auto">
    <form v-if="!uiFlags.isFetchingItem" @submit.prevent="updateAccount">
      <div class="flex flex-row p-4 border-b border-slate-25 dark:border-slate-800">
        <div class="flex-grow-0 flex-shrink-0 flex-[25%] min-w-0 py-4 pr-6 pl-0">
          <h4 class="block-title text-black-900 dark:text-slate-200">
            {{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.TITLE') }}
          </h4>
          <p>{{ $t('GENERAL_SETTINGS.FORM.GENERAL_SECTION.NOTE') }}</p>
        </div>
        <div class="p-4 flex-grow-0 flex-shrink-0 flex-[50%]">
          <label :class="{ error: $v.name.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.NAME.LABEL') }}
            <input v-model="name" type="text" :placeholder="$t('GENERAL_SETTINGS.FORM.NAME.PLACEHOLDER')"
              @blur="$v.name.$touch" />
            <span v-if="$v.name.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.NAME.ERROR') }}
            </span>
          </label>
          <label :class="{ error: $v.locale.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.LABEL') }}
            <select v-model="locale">
              <option v-for="lang in languagesSortedByCode" :key="lang.iso_639_1_code" :value="lang.iso_639_1_code">
                {{ lang.name }}
              </option>
            </select>
            <span v-if="$v.locale.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.ERROR') }}
            </span>
          </label>
          <label v-if="featureInboundEmailEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.FEATURES.INBOUND_EMAIL_ENABLED') }}
          </label>
          <label v-if="featureCustomReplyDomainEnabled">
            {{
              $t('GENERAL_SETTINGS.FORM.FEATURES.CUSTOM_EMAIL_DOMAIN_ENABLED')
            }}
          </label>
          <label v-if="featureCustomReplyDomainEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.DOMAIN.LABEL') }}
            <input v-model="domain" type="text" :placeholder="$t('GENERAL_SETTINGS.FORM.DOMAIN.PLACEHOLDER')" />
          </label>
          <label v-if="featureCustomReplyEmailEnabled">
            {{ $t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.LABEL') }}
            <input v-model="supportEmail" type="text" :placeholder="$t('GENERAL_SETTINGS.FORM.SUPPORT_EMAIL.PLACEHOLDER')
              " />
          </label>
          <label v-if="showAutoResolutionConfig" :class="{ error: $v.autoResolveDuration.$error }">
            {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.LABEL') }}
            <input v-model="autoResolveDuration" type="number" :placeholder="$t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.PLACEHOLDER')
              " @blur="$v.autoResolveDuration.$touch" />
            <span v-if="$v.autoResolveDuration.$error" class="message">
              {{ $t('GENERAL_SETTINGS.FORM.AUTO_RESOLVE_DURATION.ERROR') }}
            </span>
          </label>
        </div>
      </div>

      <div v-if="isKedu" class="small-12 row profile--settings--row">
        <div class="columns small-3">
          <h4 class="block-title text-black-900 dark:text-slate-200">
            {{ $t('SCHOOL_ACCOUNT.FORM.SECTION_TITLE') }}
          </h4>
        </div>

        <div class="columns small-9 medium-5">
          <div class="row" v-if="!isNewSchoolAccount">
            <div class="medium-9 columns">
              <label>
                {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_SELECT.LABEL') }}
              </label>
              <multiselect v-model="selectedSchoolAccount" track-by="id" label="school_name"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_SELECT.PLACEHOLDER')" selected-label
                :select-label="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_SELECT.SELECT_PLACEHOLDER')"
                :deselect-label="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_SELECT.REMOVE')" :max-height="160"
                :options="schoolAccounts" :allow-empty="true" :option-height="104" />

            </div>

            <div class="medium-3 columns add-button-area">
              <div class="add-button">
                <woot-button size="small" variant="link" icon="add" @click="newSchoolAccountButtonToggle">
                  {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_SELECT.NEW_SCHOOL_BUTTON_LABEL') }}
                </woot-button>
              </div>
            </div>
          </div>


          <div v-if="selectedSchoolAccount">
            <label
              :class="{ error: $v.selectedSchoolAccount.school_name && $v.selectedSchoolAccount.school_name.$error }">
              {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_NAME.LABEL') }}
              <input v-model.trim="selectedSchoolAccount.school_name" type="text"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_NAME.PLACEHOLDER')" />
              <span v-if="$v.selectedSchoolAccount.school_name && $v.selectedSchoolAccount.school_name.$error"
                class="message">
                {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_NAME.ERROR_MESSAGE') }}
              </span>
            </label>

            <label
              :class="{ error: $v.selectedSchoolAccount.school_corporate_name && $v.selectedSchoolAccount.school_corporate_name.$error }">
              {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_CORPORATE_NAME.LABEL') }}
              <input v-model.trim="selectedSchoolAccount.school_corporate_name" type="text"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_CORPORATE_NAME.PLACEHOLDER')" />
              <span
                v-if="$v.selectedSchoolAccount.school_corporate_name && $v.selectedSchoolAccount.school_corporate_name.$error"
                class="message">
                {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_CORPORATE_NAME.ERROR_MESSAGE') }}
              </span>
            </label>

            <label
              :class="{ error: $v.selectedSchoolAccount.school_document && $v.selectedSchoolAccount.school_document.$error }">
              {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_DOCUMENT.LABEL') }}
              <input v-model.trim="selectedSchoolAccount.school_document" type="text"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_DOCUMENT.PLACEHOLDER')" v-mask="'##.###.###/####-##'" />
              <span v-if="$v.selectedSchoolAccount.school_document && $v.selectedSchoolAccount.school_document.$error"
                class="message">
                {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_DOCUMENT.ERROR_MESSAGE') }}
              </span>
            </label>

            <label>
              {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_ADDRESS.LABEL') }}
              <input v-model.trim="selectedSchoolAccount.school_address" type="text"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_ADDRESS.PLACEHOLDER')" />
            </label>

            <label>
              {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_DISTRICT.LABEL') }}
              <input v-model.trim="selectedSchoolAccount.school_district" type="text"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_DISTRICT.PLACEHOLDER')" />
            </label>

            <label
              :class="{ error: $v.selectedSchoolAccount.school_city && $v.selectedSchoolAccount.school_city.$error }">
              {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_CITY.LABEL') }}
              <input v-model.trim="selectedSchoolAccount.school_city" type="text"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_CITY.PLACEHOLDER')" />
              <span v-if="$v.selectedSchoolAccount.school_city && $v.selectedSchoolAccount.school_city.$error"
                class="message">
                {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_CITY.ERROR_MESSAGE') }}
              </span>
            </label>

            <div class="row">
              <label>
                {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_STATE.LABEL') }}
              </label>
              <multiselect v-model="selectedSchoolAccount.school_state" track-by="ID" label="NAME"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_STATE.PLACEHOLDER')" selected-label
                :select-label="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_STATE.SELECT_PLACEHOLDER')"
                :deselect-label="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_STATE.REMOVE')" :max-height="160"
                :options="$t('SCHOOL_ACCOUNT.FORM.STATE.OPTIONS')" :option-height="104" />
            </div>

            <label
              :class="{ error: $v.selectedSchoolAccount.school_zip_code && $v.selectedSchoolAccount.school_zip_code.$error }">
              {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_ZIP_CODE.LABEL') }}
              <input v-model.trim="selectedSchoolAccount.school_zip_code" type="text"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_ZIP_CODE.PLACEHOLDER')" v-mask="'#####-###'" />
              <span v-if="$v.selectedSchoolAccount.school_zip_code && $v.selectedSchoolAccount.school_zip_code.$error"
                class="message">
                {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_ZIP_CODE.ERROR_MESSAGE') }}
              </span>
              <div class="medium-12 field-button">
                <woot-button variant="clear" @click.prevent="searchSchoolZipCode" size="small">
                  {{ this.$t('SCHOOL_ACCOUNT.FORM.SEARCH_ZIPCODE.BUTTON_LABEL') }}
                </woot-button>
              </div>
            </label>

            <div class="row">
              <label>
                {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_PHONE_NUMBER.LABEL') }}
              </label>
              <input v-model.trim="selectedSchoolAccount.school_phone_number" type="text"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_PHONE_NUMBER.PLACEHOLDER')" v-mask="'###########'" />
            </div>

            <label
              :class="{ error: $v.selectedSchoolAccount.school_email && $v.selectedSchoolAccount.school_email.$error }">
              {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_EMAIL.LABEL') }}
              <input v-model.trim="selectedSchoolAccount.school_email" type="text"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_EMAIL.PLACEHOLDER')" />
              <span v-if="$v.selectedSchoolAccount.school_email && $v.selectedSchoolAccount.school_email.$error"
                class="message">
                {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_EMAIL.ERROR_MESSAGE') }}
              </span>
            </label>

            <div class="row" v-if="currentUser.type === 'SuperAdmin'">
              <label>
                {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_CONTRACT_INSTALLMENT_MATURITY_OPTION.LABEL') }}
              </label>
              <multiselect v-model="selectedSchoolAccount.installments_maturity_options"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_CONTRACT_INSTALLMENT_MATURITY_OPTION.PLACEHOLDER')"
                selected-label
                :select-label="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_CONTRACT_INSTALLMENT_MATURITY_OPTION.SELECT_PLACEHOLDER')"
                :deselect-label="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_CONTRACT_INSTALLMENT_MATURITY_OPTION.REMOVE')"
                :max-height="160" :options="Array.from({ length: 31 }, (_, i) => i + 1)" :option-height="104"
                :multiple="true" :close-on-select="false" :clear-on-select="false" />
            </div>

            <div class="row" v-if="currentUser.type === 'SuperAdmin'">
              <label>
                {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_CONTRACT_INSTALLMENT_QUANTITY_OPTION.LABEL') }}
              </label>
              <multiselect v-model="selectedSchoolAccount.installments_quantity_options"
                :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_CONTRACT_INSTALLMENT_QUANTITY_OPTION.PLACEHOLDER')"
                selected-label
                :select-label="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_CONTRACT_INSTALLMENT_QUANTITY_OPTION.SELECT_PLACEHOLDER')"
                :deselect-label="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_CONTRACT_INSTALLMENT_QUANTITY_OPTION.REMOVE')"
                :max-height="160" :options="Array.from({ length: 24 }, (_, i) => i + 1)" :option-height="104"
                :multiple="true" :close-on-select="false" :clear-on-select="false" />
            </div>

            <div class="medium-12 columns" v-if="currentUser.type === 'SuperAdmin'">
              <input v-model.trim="selectedSchoolAccount.enable_manual_contract" type="checkbox" />
              <label>
                {{ $t('SCHOOL_ACCOUNT.FORM.MANUAL_SCHOOL_CONTRACTS_ENABLE.LABEL') }}
              </label>
            </div>

            <div class="medium-12 columns" v-if="currentUser.type === 'SuperAdmin'">
              <input v-model.trim="selectedSchoolAccount.extension_session_option_enable" type="checkbox" />
              <label>
                {{ $t('SCHOOL_ACCOUNT.FORM.EXTENSION_SESSION_OPTION_ENABLE.LABEL') }}
              </label>
            </div>

            <div class="medium-12 columns" v-if="currentUser.type === 'SuperAdmin'">
              <input v-model.trim="selectedSchoolAccount.extension_session_single_billing_enable" type="checkbox" />
              <label>
                {{ $t('SCHOOL_ACCOUNT.FORM.EXTENSION_SESSION_SINGLE_BILLING_ENABLE.LABEL') }}
              </label>
            </div>

            <div class="modal-footer">
              <div class="medium-12 columns">
                <woot-submit-button type="button" :button-text="$t('SCHOOL_ACCOUNT.FORM.SUBMIT_BUTTON_LABEL')"
                  @click="onSubmitSchoolAccount(selectedSchoolAccount)" />

                <button class="button clear" @click.prevent="onCancelSchoolAccount">
                  {{ $t('SCHOOL_ACCOUNT.FORM.CANCEL_BUTTON_LABEL') }}
                </button>
              </div>
            </div>

          </div>


        </div>
      </div>

      <div class="profile--settings--row row">
        <div class="columns small-3">
          <h4 class="block-title text-black-900 dark:text-slate-200">
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.TITLE') }}
          </h4>
          <p>
            {{ $t('GENERAL_SETTINGS.FORM.ACCOUNT_ID.NOTE') }}
          </p>
        </div>
        <div class="p-4 flex-grow-0 flex-shrink-0 flex-[50%]">
          <woot-code :script="getAccountId" />
        </div>
      </div>
      <div class="text-sm text-center p-4">
        <div>{{ `v${globalConfig.appVersion}` }}</div>
        <div v-if="hasAnUpdateAvailable && globalConfig.displayManifest">
          {{
            $t('GENERAL_SETTINGS.UPDATE_CHATWOOT', {
              latestChatwootVersion: latestChatwootVersion,
            })
          }}
        </div>
        <div class="build-id">
          <div>{{ `Build ${globalConfig.gitSha}` }}</div>
        </div>
      </div>

      <woot-submit-button class="button nice success button--fixed-top" :button-text="$t('GENERAL_SETTINGS.SUBMIT')"
        :loading="isUpdating" />
    </form>

    <woot-loading-state v-if="uiFlags.isFetchingItem" />
  </div>
</template>

<script>
import { required, minValue, maxValue, minLength, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { isKeduFieldValid } from 'shared/helpers/KeduValidators';
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
import maskMixin from 'shared/mixins/inputMaskMixin';
import zipCodeMixin from 'shared/mixins/zipCodeMixin';
import { FEATURE_FLAGS } from '../../../../featureFlags';
const semver = require('semver');
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import { getLanguageDirection } from 'dashboard/components/widgets/conversation/advancedFilterItems/languages';

export default {
  mixins: [accountMixin, alertMixin, configMixin, uiSettingsMixin, maskMixin, zipCodeMixin],
  data() {
    return {
      id: '',
      name: '',
      locale: 'en',
      domain: '',
      supportEmail: '',
      features: {},
      autoResolveDuration: null,
      latestChatwootVersion: null,

      selectedSchoolAccount: null,
      isNewSchoolAccount: false,
    };
  },
  validations() {

    let validations = {
      name: {
        required,
      },
      locale: {
        required,
      },
      autoResolveDuration: {
        minValue: minValue(1),
        maxValue: maxValue(999),
      },
    };

    if (this.isKedu && this.selectedSchoolAccount) {
      validations = {
        ...validations, selectedSchoolAccount: {
          school_name: { required, minLength: minLength(4) },
          school_corporate_name: { required },
          school_document: { required },
          school_email: { email },
          school_zip_code: { keduValidator: this.checkDataValidation('zipCode') },
          school_city: { keduValidator: this.checkDataValidation('city') }
        }
      }
    }

    return validations;
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      currentUser: 'getCurrentUser',
      getAccount: 'accounts/getAccount',
      uiFlags: 'accounts/getUIFlags',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
    }),
    schoolAccounts() {
      return this.getAccount(this.accountId).school_accounts?.map(school_account => ({
        ...school_account,
        school_state: this.$t('SCHOOL_ACCOUNT.FORM.STATE.OPTIONS')?.find && this.$t('SCHOOL_ACCOUNT.FORM.STATE.OPTIONS').find(state_option => state_option.ID === school_account.school_state),
      })) || [];
    },
    schoolClasses() {
      return this.selectedSchoolAccount?.school_class_rules?.map(school_class => ({
        ...school_class,
        schooling_type: school_class.schooling_type?.VALUE,
        contract_installment_value: parseFloat(school_class.contract_installment_value).toFixed(2),
        contract_annuity: parseFloat(school_class.contract_annuity).toFixed(2),
        enrollment_tuition: parseFloat(school_class.enrollment_tuition).toFixed(2),
        enrollment_discount: parseFloat(school_class.enrollment_discount).toFixed(2),
      })) || [];
    },
    checkDataValidation() {
      return (dataParams) => (value) => value ? isKeduFieldValid(value, dataParams) : true;
    },
    showAutoResolutionConfig() {
      return this.isFeatureEnabledonAccount(
        this.accountId,
        FEATURE_FLAGS.AUTO_RESOLVE_CONVERSATIONS
      );
    },
    hasAnUpdateAvailable() {
      if (!semver.valid(this.latestChatwootVersion)) {
        return false;
      }

      return semver.lt(
        this.globalConfig.appVersion,
        this.latestChatwootVersion
      );
    },
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    isUpdating() {
      return this.uiFlags.isUpdating;
    },

    featureInboundEmailEnabled() {
      return !!this.features.inbound_emails;
    },

    featureCustomReplyDomainEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_domain
      );
    },

    featureCustomReplyEmailEnabled() {
      return (
        this.featureInboundEmailEnabled && !!this.features.custom_reply_email
      );
    },

    getAccountId() {
      return this.id.toString();
    },

    days() {
      return [5, 10, 15, 20];
    },
    installmentsNumbersList() {
      return [1, 10, 11, 12, 13];
    }
  },
  mounted() {
    if (!this.id) {
      this.initializeAccount();
    }
  },

  methods: {
    async initializeAccount() {
      try {
        await this.$store.dispatch('accounts/get');
        const {
          name,
          locale,
          id,
          domain,
          support_email,
          features,
          auto_resolve_duration,
          latest_chatwoot_version: latestChatwootVersion,
        } = this.getAccount(this.accountId);

        this.$root.$i18n.locale = locale;
        this.name = name;
        this.locale = locale;
        this.id = id;
        this.domain = domain;
        this.supportEmail = support_email;
        this.features = features;
        this.autoResolveDuration = auto_resolve_duration;
        this.latestChatwootVersion = latestChatwootVersion;

      } catch (error) {
        // Ignore error
      }
    },

    newSchoolAccountButtonToggle() {
      this.isNewSchoolAccount = true;
      this.selectedSchoolAccount = {
        id: null,

        school_address: '',
        school_city: '',
        school_district: '',
        school_document: '',
        school_email: '',
        school_state: '',
        school_name: '',
        school_corporate_name: '',
        school_phone_number: '',
        installments_maturity_options: [5, 10, 15, 20],
        installments_quantity_options: [1, 10, 11, 12, 13],
        school_zip_code: '',
        extension_session_option_enable: false,
        extension_session_single_billing_enable: false,
        enable_manual_contract: false
      }
    },

    onCancelSchoolAccount() {
      if (this.isNewSchoolAccount) {
        this.isNewSchoolAccount = false;
      }
      this.selectedSchoolAccount = null;
    },

    async onSubmitSchoolAccount(selectedSchoolAccount) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'));
        return;
      }

      try {
        let payload = {
          school_accounts: [selectedSchoolAccount].map(school => {

            let schoolAccountPayload = {
              ...selectedSchoolAccount,
              school_state: school.school_state?.ID,
              installments_maturity_options: selectedSchoolAccount.installments_maturity_options.sort((a, b) => a - b),
              installments_quantity_options: selectedSchoolAccount.installments_quantity_options.sort((a, b) => a - b)
            }

            return schoolAccountPayload
          })
        };

        await this.$store.dispatch('accounts/update', payload);
        if (this.isNewSchoolAccount) {
          this.isNewSchoolAccount = false;
        }
        this.selectedSchoolAccount = null;
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'));
      } catch (error) {
        if (error?.cause && error.cause['school_accounts.school_document']) {
          this.showAlert(this.$t('SCHOOL_ACCOUNT.FORM.SCHOOL_DOCUMENT.UNIQUENESS_ERROR_MESSAGE'));
        } else {
          this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'));
        }
      }
    },

    async updateAccount() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('GENERAL_SETTINGS.FORM.ERROR'));
        return;
      }
      try {
        let payload = {
          locale: this.locale,
          name: this.name,
          domain: this.domain,
          support_email: this.supportEmail,
          auto_resolve_duration: this.autoResolveDuration,
        }

        await this.$store.dispatch('accounts/update', payload);
        this.$root.$i18n.locale = this.locale;
        this.getAccount(this.id).locale = this.locale;
        this.updateDirectionView(this.locale);
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'));
      }
    },

    updateDirectionView(locale) {
      const isRTLSupported = getLanguageDirection(locale);
      this.updateUISettings({
        rtl_view: isRTLSupported,
      });
    },

    async searchSchoolZipCode() {
      try {
        const response = await this.fetchZipCode(this.selectedSchoolAccount.school_zip_code);
        const { result } = response.data;

        this.selectedSchoolAccount.school_state = this.$t('SCHOOL_CONTACT_FORM.FORM.STATE.OPTIONS').find(option => option.ID === result.stateShortname);
        this.selectedSchoolAccount.school_city = result.city;
        this.selectedSchoolAccount.school_district = result.district;
        this.selectedSchoolAccount.school_address = result.street;
      } catch (error) {
        this.showAlert(this.$t('SCHOOL_CONTACT_FORM.FORM.SEARCH_ZIPCODE.API_ERROR'));
      }
    }
  },
};
</script>
