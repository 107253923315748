import {
  OPERATOR_TYPES_1,
  OPERATOR_TYPES_3,
  OPERATOR_TYPES_5,
} from 'dashboard/components/widgets/FilterInput/FilterOperatorTypes.js';
const filterTypes = [
  {
    attributeKey: 'name',
    attributeI18nKey: 'NAME',
    inputType: 'plain_text',
    dataType: 'text',
    filterOperators: OPERATOR_TYPES_1,
    attributeModel: 'contact_attribute',
  },
  {
    attributeKey: 'email',
    attributeI18nKey: 'EMAIL',
    inputType: 'plain_text',
    dataType: 'text',
    filterOperators: OPERATOR_TYPES_3,
    attributeModel: 'contact_attribute',
  },
  {
    attributeKey: 'phone_number',
    attributeI18nKey: 'PHONE_NUMBER',
    inputType: 'plain_text',
    dataType: 'text',
    filterOperators: OPERATOR_TYPES_3,
    attributeModel: 'contact_attribute',
  },
  {
    attributeKey: 'identifier',
    attributeI18nKey: 'IDENTIFIER',
    inputType: 'plain_text',
    dataType: 'number',
    filterOperators: OPERATOR_TYPES_1,
    attributeModel: 'contact_attribute',
  },
  {
    attributeKey: 'country_code',
    attributeI18nKey: 'COUNTRY',
    inputType: 'search_select',
    dataType: 'number',
    filterOperators: OPERATOR_TYPES_1,
    attributeModel: 'contact_attribute',
  },
  {
    attributeKey: 'city',
    attributeI18nKey: 'CITY',
    inputType: 'plain_text',
    dataType: 'Number',
    filterOperators: OPERATOR_TYPES_3,
    attributeModel: 'contact_attribute',
  },
  {
    attributeKey: 'created_at',
    attributeI18nKey: 'CREATED_AT',
    inputType: 'date',
    dataType: 'text',
    filterOperators: OPERATOR_TYPES_5,
    attributeModel: 'contact_attribute',
  },
  {
    attributeKey: 'last_activity_at',
    attributeI18nKey: 'LAST_ACTIVITY',
    inputType: 'date',
    dataType: 'text',
    filterOperators: OPERATOR_TYPES_5,
    attributeModel: 'contact_attribute',
  },
  {
    attributeKey: 'referer',
    attributeI18nKey: 'REFERER_LINK',
    inputType: 'plain_text',
    dataType: 'text',
    filterOperators: OPERATOR_TYPES_5,
    attributeModel: 'school_enrollment_attribute',
  },
];

const filterAttributeGroups = [
  {
    name: 'Standard Filters',
    i18nGroup: 'STANDARD_FILTERS',
    attributes: [
      {
        key: 'name',
        i18nKey: 'NAME',
      },
      {
        key: 'email',
        i18nKey: 'EMAIL',
      },
      {
        key: 'phone_number',
        i18nKey: 'PHONE_NUMBER',
      },
      {
        key: 'identifier',
        i18nKey: 'IDENTIFIER',
      },
      {
        key: 'country_code',
        i18nKey: 'COUNTRY',
      },
      {
        key: 'city',
        i18nKey: 'CITY',
      },
      {
        key: 'created_at',
        i18nKey: 'CREATED_AT',
      },
      {
        key: 'last_activity_at',
        i18nKey: 'LAST_ACTIVITY',
      },
    ],
  },
];

if(window.chatwootConfig.isKedu){
  filterTypes.push(
    {
      attributeKey: 'school_enrollments.number',
      attributeI18nKey: 'ENROLLMENT_NUMBER',
      inputType: 'plain_text',
      dataType: 'text',
      filterOperators: OPERATOR_TYPES_1,
      attributeModel: 'school_enrollment_attribute',
    },
    {
      attributeKey: 'school_enrollments.student_name',
      attributeI18nKey: 'STUDENT_NAME',
      inputType: 'plain_text',
      dataType: 'text',
      filterOperators: OPERATOR_TYPES_1,
      attributeModel: 'school_enrollment_attribute',
    },
    {
      attributeKey: 'school_enrollments.student_first_document',
      attributeI18nKey: 'STUDENT_FIRST_DOCUMENT',
      inputType: 'plain_text',
      dataType: 'text',
      filterOperators: OPERATOR_TYPES_1,
      attributeModel: 'school_enrollment_attribute',
    },
    {
      attributeKey: 'school_enrollments.responsible_name',
      attributeI18nKey: 'RESPONSIBLE_NAME',
      inputType: 'plain_text',
      dataType: 'text',
      filterOperators: OPERATOR_TYPES_1,
      attributeModel: 'school_enrollment_attribute',
    },
    {
      attributeKey: 'school_enrollments.responsible_first_document',
      attributeI18nKey: 'RESPONSIBLE_FIRST_DOCUMENT',
      inputType: 'plain_text',
      dataType: 'text',
      filterOperators: OPERATOR_TYPES_1,
      attributeModel: 'school_enrollment_attribute',
    },
    {
      attributeKey: 'school_enrollments.created_at',
      attributeI18nKey: 'CREATED_AT',
      inputType: 'date',
      dataType: 'text',
      filterOperators: OPERATOR_TYPES_5,
      attributeModel: 'school_enrollment_attribute',
    },
  );
  filterAttributeGroups.push({
    name: 'Enrollments Filters',
    i18nGroup: 'ENROLLMENT_FILTERS',
    attributes: [
      {
        key: 'school_enrollments.number',
        i18nKey: 'ENROLLMENT_NUMBER',
      },
      {
        key: 'school_enrollments.student_name',
        i18nKey: 'STUDENT_NAME',
      },
      {
        key: 'school_enrollments.student_first_document',
        i18nKey: 'STUDENT_FIRST_DOCUMENT',
      },
      {
        key: 'school_enrollments.responsible_name',
        i18nKey: 'RESPONSIBLE_NAME',
      },
      {
        key: 'school_enrollments.responsible_first_document',
        i18nKey: 'RESPONSIBLE_FIRST_DOCUMENT',
      },
      {
        key: 'school_enrollments.created_at',
        i18nKey: 'CREATED_AT',
      }
    ],
  },);
  filterTypes.push(
    {
      attributeKey: 'school_contracts.contractor_name',
      attributeI18nKey: 'CONTRACT_STUDENT_NAME',
      inputType: 'plain_text',
      dataType: 'text',
      filterOperators: OPERATOR_TYPES_1,
      attributeModel: 'school_contract_attribute',
    },
    {
      attributeKey: 'school_contracts.contract_student_name',
      attributeI18nKey: 'CONTRACT_CONTRACTOR_NAME',
      inputType: 'plain_text',
      dataType: 'text',
      filterOperators: OPERATOR_TYPES_1,
      attributeModel: 'school_contract_attribute',
    },
    {
      attributeKey: 'school_contracts.contractor_first_document',
      attributeI18nKey: 'CONTRACT_CONTRACTOR_FIRST_DOCUMENT',
      inputType: 'plain_text',
      dataType: 'text',
      filterOperators: OPERATOR_TYPES_1,
      attributeModel: 'school_contract_attribute',
    },
    {
      attributeKey: 'school_contracts.created_at',
      attributeI18nKey: 'CONTRACT_FILLING_DATE',
      inputType: 'date',
      dataType: 'text',
      filterOperators: OPERATOR_TYPES_5,
      attributeModel: 'school_contract_attribute',
    },
    {
      attributeKey: 'school_documents.created_at',
      attributeI18nKey: 'CONTRACT_GENERATION_DATE',
      inputType: 'date',
      dataType: 'text',
      filterOperators: OPERATOR_TYPES_5,
      attributeModel: 'school_contract_attribute',
    },
  );
  filterAttributeGroups.push({
    name: 'Contract Filters',
    i18nGroup: 'CONTRACT_FILTERS',
    attributes: [
      {
        key: 'school_contracts.contractor_name',
        i18nKey: 'CONTRACT_CONTRACTOR_NAME',
      },
      {
        key: 'school_contracts.contract_student_name',
        i18nKey: 'CONTRACT_STUDENT_NAME',
      },
      {
        key: 'school_contracts.contractor_first_document',
        i18nKey: 'CONTRACT_CONTRACTOR_FIRST_DOCUMENT',
      },
      {
        key: 'school_contracts.created_at',
        i18nKey: 'CONTRACT_FILLING_DATE',
      },
      {
        key: 'school_documents.created_at',
        i18nKey: 'CONTRACT_GENERATION_DATE',
      }
    ],
  },);
}

export { filterAttributeGroups };
export default filterTypes;
