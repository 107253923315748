<template>
  <div>
    <woot-modal-header :header-title="schoolClassRule ? $t('SCHOOL_CLASSES.EDIT_MODAL_TITLE') : $t('SCHOOL_CLASSES.ADD_MODAL_TITLE')" />
    <div class="flex flex-col modal-content h-5/6">
      <div>
        <label>
          {{ $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.LABEL') }}
        </label>
        <multiselect v-model="schoolingType" track-by="VALUE" label="LABEL"
          :placeholder="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.PLACEHOLDER')" selected-label
          :select-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.SELECT_PLACEHOLDER')"
          :deselect-label="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.REMOVE')" :max-height="160"
          :options="$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.OPTIONS')" :allow-empty="false"
          :option-height="104" :preselect-first="true" />
      </div>

      <div>
        <label>
          {{ $t('SCHOOL_ACCOUNT.FORM.SCHOOL_YEAR.LABEL') }}
        </label>
        <multiselect v-model="schoolYear"
          :placeholder="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_YEAR.PLACEHOLDER')" selected-label
          :select-label="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_YEAR.SELECT_PLACEHOLDER')"
          :deselect-label="$t('SCHOOL_ACCOUNT.FORM.SCHOOL_YEAR.REMOVE')" :max-height="160"
          :options="schoolYearOptions" :allow-empty="true" :option-height="104" />
      </div>

      <div>
        <label>
          {{ $t('SCHOOL_CLASSES.FORM.SCHOOL_CLASS_SELECT.LABEL') }}
        </label>
        <multiselect v-model="schoolClass" :placeholder="$t('SCHOOL_CLASSES.FORM.SCHOOL_CLASS_SELECT.PLACEHOLDER')"
          selected-label :select-label="$t('SCHOOL_CLASSES.FORM.SCHOOL_CLASS_SELECT.SELECT_PLACEHOLDER')"
          :deselect-label="$t('SCHOOL_CLASSES.FORM.SCHOOL_CLASS_SELECT.REMOVE')" :max-height="160" track-by="id" label="serie"
          :options="schoolClassOptions" :allow-empty="false" :option-height="104" :preselect-first="true" @open="findSchoolClassOptions" 
          :disabled="!this.schoolYear || !this.schoolingType.VALUE"
          />
      </div>

      <label>
        {{ $t('SCHOOL_ACCOUNT.FORM.ANNUITY.LABEL') }}
        <input v-model.trim="contractAnnuity" type="text" :placeholder="$t('SCHOOL_ACCOUNT.FORM.ANNUITY.PLACEHOLDER')"
          v-mask="moneyMask" />
      </label>

      <div>
        <label>
          {{ $t('SCHOOL_ACCOUNT.FORM.INSTALLMENTS_QUANTITY.LABEL') }}
        </label>
        <multiselect v-model="contractInstallmentNumber"
          :placeholder="$t('SCHOOL_ACCOUNT.FORM.INSTALLMENTS_QUANTITY.PLACEHOLDER')" selected-label
          :select-label="$t('SCHOOL_ACCOUNT.FORM.INSTALLMENTS_QUANTITY.SELECT_PLACEHOLDER')"
          :deselect-label="$t('SCHOOL_ACCOUNT.FORM.INSTALLMENTS_QUANTITY.REMOVE')" :max-height="160"
          :options="installmentsNumbersList" :allow-empty="true" :option-height="104" />
      </div>

      <label>
        {{ $t('SCHOOL_ACCOUNT.FORM.INSTALLMENT_VALUE.LABEL') }}
        <input v-model.trim="contractInstallmentValue" type="text"
          :placeholder="$t('SCHOOL_ACCOUNT.FORM.INSTALLMENT_VALUE.PLACEHOLDER')" v-mask="moneyMask" />
      </label>

      <label>
        {{ $t('SCHOOL_ACCOUNT.FORM.PUNCTUALITY_DISCOUNT_PERCENTAGE.LABEL') }}
        <input v-model.trim="contractPunctualityDiscountPercentage" type="number"
          :placeholder="$t('SCHOOL_ACCOUNT.FORM.PUNCTUALITY_DISCOUNT_PERCENTAGE.PLACEHOLDER')" step="0.01" min="0"
          max="100" />
      </label>

      <label>
        {{ $t('SCHOOL_ACCOUNT.FORM.WITHDRAWAL_FEE_PERCENTAGE.LABEL') }}
        <input v-model.trim="contractWithdrawalFeePercentage" type="number"
          :placeholder="$t('SCHOOL_ACCOUNT.FORM.WITHDRAWAL_FEE_PERCENTAGE.PLACEHOLDER')" step="0.01" min="0" />
      </label>

      <label>
        {{ $t('SCHOOL_ACCOUNT.FORM.TUITION.LABEL') }}
        <input v-model.trim="enrollmentTuition" type="text" :placeholder="$t('SCHOOL_ACCOUNT.FORM.TUITION.PLACEHOLDER')"
          v-mask="moneyMask" />
      </label>

      <label>
        {{ $t('SCHOOL_ACCOUNT.FORM.DISCOUNT.LABEL') }}
        <input v-model.trim="enrollmentDiscount" type="text"
          :placeholder="$t('SCHOOL_ACCOUNT.FORM.DISCOUNT.PLACEHOLDER')" v-mask="moneyMask">
      </label>

      <div class="medium-12 columns">
        <input v-model.trim="enrollmentPunctualityDiscountRuleEnable" type="checkbox" />
        <label>
          {{ $t('SCHOOL_ACCOUNT.FORM.PUNCTUALITY_DISCOUNT_RULE_ENABLE.LABEL') }}
        </label>
      </div>

      <label>
        {{ $t('SCHOOL_ACCOUNT.FORM.COST_CENTER.LABEL') }}
        <input v-model.trim="costCenter" type="text"
          :placeholder="$t('SCHOOL_ACCOUNT.FORM.COST_CENTER.PLACEHOLDER')" disabled>
      </label>

      <div class="w-full">
        <woot-button @click.prevent="preparePayloadToSubmit">
          {{ $t('SCHOOL_CLASSES.FORM.SUBMIT') }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('SCHOOL_CLASSES.FORM.CANCEL') }}
        </woot-button>
      </div>

    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import maskMixin from 'shared/mixins/inputMaskMixin';
import AccountsApi from 'dashboard/api/account.js';

export default {

  components: {

  },
  mixins: [alertMixin, maskMixin],
  props: {
    onClose: {
      type: Function,
      default: () => { },
    },
    onSubmit: {
      type: Function,
      default: () => { },
    },
    schoolClassRule: {
      type: Object,
      default: () => { },
    },
    schoolAccount: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      schoolClassOptions: [],

      show: true,
      schoolingType: '',
      schoolYear: '',
      costCenter: '',
      schoolClass: '',
      contractAnnuity: '',
      contractInstallmentNumber: '',
      contractPunctualityDiscountPercentage: '',
      enrollmentPunctualityDiscountRuleEnable: '',
      contractWithdrawalFeePercentage: '',
      contractInstallmentValue: '',
      enrollmentTuition: '',
      enrollmentDiscount: ''
    };
  },
  mounted() {
    this.id = this.schoolClassRule?.id;
    this.schoolingType = this.$t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.OPTIONS').find(option => option.VALUE === this.schoolClassRule?.schooling_type) || '';
    this.schoolYear = this.schoolClassRule?.school_year;
    this.costCenter = this.schoolClassRule?.cost_center;
    this.contractAnnuity = parseFloat(this.schoolClassRule?.contract_annuity).toFixed(2);
    this.contractInstallmentNumber = this.schoolClassRule?.contract_installment_number;
    this.contractPunctualityDiscountPercentage = this.schoolClassRule?.contract_punctuality_discount_percentage;
    this.enrollmentPunctualityDiscountRuleEnable = this.schoolClassRule?.enrollment_punctuality_discount_rule_enable;
    this.contractWithdrawalFeePercentage = this.schoolClassRule?.contract_withdrawal_fee_percentage;
    this.contractInstallmentValue = parseFloat(this.schoolClassRule?.contract_installment_value).toFixed(2);
    this.enrollmentTuition = parseFloat(this.schoolClassRule?.enrollment_tuition).toFixed(2);
    this.enrollmentDiscount = parseFloat(this.schoolClassRule?.enrollment_discount).toFixed(2);

    this.schoolClassRule?.school_class && this.initializeClassSelect(this.schoolClassRule?.school_class)
  },

  computed: {
    installmentsNumbersList() {
      return [1, 10, 11, 12, 13];
    },
    schoolYearOptions() {
      return [2024, 2025]
    }
  },

  watch: {
    schoolClassRule: {
      handler(val) {
        if (val) {
          if (val.contract_punctuality_discount_percentage > 100) {
            this.schoolClassRule.contract_punctuality_discount_percentage = 100;
          }

          const parsedTuition = this.moneyMaskToDecimal(val.enrollment_tuition);
          const parsedVal = this.moneyMaskToDecimal(val.enrollment_discount);

          if (parsedVal > parsedTuition) {
            this.schoolClassRule.enrollment_discount = val.enrollment_tuition;
          }
        }
      },
      deep: true
    },
    schoolingType() {
      this.schoolClass = null;
    },
    schoolYear(newYear) {
      this.costCenter = `KEDU ONE MATRICULA ${newYear || ''}`;
      this.schoolClass = null;
    }
  },

  methods: {
    payload() {
      return {
        id: this.id,
        schooling_type: this.schoolingType.VALUE,
        school_year: this.schoolYear,
        cost_center: this.costCenter,
        school_class: this.schoolClass.serie,
        contract_installment_number: this.contractInstallmentNumber,
        contract_punctuality_discount_percentage: this.contractPunctualityDiscountPercentage,
        enrollment_punctuality_discount_rule_enable: this.enrollmentPunctualityDiscountRuleEnable,
        contract_withdrawal_fee_percentage: this.contractWithdrawalFeePercentage,
        contract_annuity: this.moneyMaskToDecimal(this.contractAnnuity),
        contract_installment_value: this.moneyMaskToDecimal(this.contractInstallmentValue),
        enrollment_tuition: this.moneyMaskToDecimal(this.enrollmentTuition),
        enrollment_discount: this.moneyMaskToDecimal(this.enrollmentDiscount),
      }
    },

    preparePayloadToSubmit() {
      this.onSubmit(this.payload());
      this.onClose();
    },

    async findSchoolClassOptions() {
      try {
        if(this.schoolAccount.school_document && this.schoolingType.VALUE && this.schoolYear){
          const response = await AccountsApi.searchForClasses(this.schoolAccount.school_document, this.schoolingType.VALUE, this.schoolYear);
          this.schoolClassOptions = response.data.classes;
        }
      } catch (error) {
        this.showAlert($t('SCHOOL_CLASSES.LIST.SCHOOL_ACCOUNT_SELECT.API.ERROR'));
      }
    },

    async initializeClassSelect(schoolClass) {
      await this.findSchoolClassOptions();
      this.schoolClass = this.schoolClassOptions.find(schoolClassRule => schoolClassRule.serie === schoolClass);
    }
  }
};
</script>
<style lang="scss" scoped></style>
