<template>
  <div class="settings--content">
    <woot-modal-header :header-title="$t('SCHOOL_CONTRACT_SETTINGS.EDIT_MODAL_TITLE')" />
    <settings-section :title="$t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_CONFIGURATION.TITLE')" :sub-title="$t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_CONFIGURATION.SUBTITLE')
      ">
      <div class="school-settings--content flex-col w-full gap-2">
        <label>
          {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_CONFIGURATION.TEMPLATE_KEY') }}
          <input :value="clicksignTemplateKey" type="text" disabled />
        </label>
        <label>
          {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_CONFIGURATION.TOKEN') }}
          <input :value="clicksignToken" type="text" disabled />
        </label>
      </div>
    </settings-section>

    <settings-section :title="$t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.TITLE')" :sub-title="$t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SUBTITLE')
      ">
      <div class="school-settings--content flex-col w-full gap-1">
        <div>
          <p class="text-woot-500 dark:text-woot-500 pb-1 text-xl not-italic font-medium">
            {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.STEP_1') }}
          </p>

          <p class="pb-1 text-sm not-italic font-medium">
            {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.STEP_1_DESCRIPTION') }}
          </p>
        </div>
        <label>
          {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_NAME') }}
          <input v-model="clicksignSignerName" type="text" />
        </label>

        <label>
          {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_DOCUMENT') }}
          <input v-model="clicksignSignerDocument" type="text" v-mask="'###.###.###-##'" />
        </label>

        <label>
          {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_BIRTHDAY') }}
          <input v-model="clicksignSignerBirthday" type="date" />
        </label>

        <label>
          {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_EMAIL') }}
          <input v-model="clicksignSignerEmail" type="text" />
        </label>

        <label>
          {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_KEY') }}
          <div class="flex gap-1 relative">
            <div class="flex-none">
              <woot-button color-scheme="primary" @click.prevent="sendSignerToClicksign">
                {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SEND_BUTTON') }}
              </woot-button>
            </div>
            <div class="flex-1">
              <input v-model="clicksignSignerKey" type="text" readonly />
            </div>
            <woot-button size="small" @click="onCopySignerKey" class="absolute top-0 right-0">
              {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.COPY_BUTTON') }}
            </woot-button>
          </div>
        </label>

        <div>
          <p class="text-woot-500 dark:text-woot-500 pb-1 text-xl not-italic font-medium mt-5">
            {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.STEP_2') }}
          </p>

          <p class="pb-1 text-sm not-italic font-medium">
            {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.STEP_2_DESCRIPTION') }}
          </p>
        </div>

        <div>
          <a :href="clicksignAutosignFormUrl" target="_blank"><u>{{
      $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.STEP_2_LINK_LABEL') }}</u></a>
        </div>

        <div class="mt-5">
          <div class="flex flex-row">
            <label>
              {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_SECRET') }}
            </label>
            <fluent-icon
              v-tooltip.top-start="$t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_SECRET_TOOLTIP')"
              icon="info" size="14" class="mt-px" />
          </div>
          <input v-model="clicksignSignerSecret" type="text" />
        </div>

        <div>
          <input v-model="clicksignEnableAutoSign" type="checkbox" />
          <label>
            {{ $t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.ENABLE_AUTO_SIGN') }}
          </label>
        </div>
      </div>
    </settings-section>
    <div class="flex flex-col modal-content h-5/6">
      <div class="w-full">
        <woot-button @click.prevent="updateHook">
          {{ $t('SCHOOL_CONTRACT_SETTINGS.FORM.SUBMIT') }}
        </woot-button>
        <woot-button class="button clear" @click.prevent="onClose">
          {{ $t('SCHOOL_CONTRACT_SETTINGS.FORM.CANCEL') }}
        </woot-button>
      </div>

    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { copyTextToClipboard } from 'shared/helpers/clipboard';

export default {

  components: {

  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => { },
    },
    schoolContractSettings: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      clicksignSignerEmail: null,
      clicksignSignerName: null,
      clicksignSignerDocument: null,
      clicksignSignerBirthday: null,

      clicksignSignerSecret: null,
      clicksignEnableAutoSign: false,

      show: true,
    };
  },
  mounted() {
    this.clicksignSignerSecret = this.schoolContractSettings?.settings?.signer_secret;
    this.clicksignEnableAutoSign = this.schoolContractSettings?.settings?.enable_auto_sign;
    this.clicksignSignerEmail = this.schoolContractSettings?.settings?.signer_email;
    this.clicksignSignerName = this.schoolContractSettings?.settings?.signer_name;
    this.clicksignSignerDocument = this.schoolContractSettings?.settings?.signer_documentation;
    this.clicksignSignerBirthday = this.schoolContractSettings?.settings?.signer_birthday;
  },

  computed: {
    clicksignTemplateKey() {
      return this.schoolContractSettings?.settings.template_key
    },

    clicksignToken() {
      return this.schoolContractSettings?.settings.token;
    },

    clicksignSignerKey() {
      return this.schoolContractSettings?.settings?.signer_key;
    },

    clicksignAutosignFormUrl() {
      return window.chatwootConfig.clicksignAutosignFormUrl;
    },

  },

  methods: {

    async sendSignerToClicksign() {
      try {
        await this.$store.dispatch('integrations/sendSignerToClicksign', {
          appId: 'clicksign',
          id: this.schoolContractSettings.id,
          token: this.clicksignToken,
          template_key: this.clicksignTemplateKey,
          signer: {
            email: this.clicksignSignerEmail,
            name: this.clicksignSignerName,
            documentation: this.clicksignSignerDocument,
            birthday: this.clicksignSignerBirthday
          }
        });

        this.showAlert(this.$t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_KEY_SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SIGNER_KEY_ERROR_MESSAGE'));
      }
    },

    async updateHook() {
      try {
        await this.$store.dispatch('integrations/updateHook', {
          id: this.schoolContractSettings.id,
          settings: {
            ...this.schoolContractSettings.settings,
            signer_key: this.clicksignSignerKey,
            signer_email: this.clicksignSignerEmail,
            signer_name: this.clicksignSignerName,
            signer_documentation: this.clicksignSignerDocument,
            signer_birthday: this.clicksignSignerBirthday,
            signer_secret: this.clicksignSignerSecret,
            enable_auto_sign: this.clicksignEnableAutoSign,
          },
        });

        this.showAlert(this.$t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SECRET_SUCCESS_MESSAGE'));
      } catch (error) {
        this.showAlert(this.$t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.SECRET_ERROR_MESSAGE'));
      }
    },

    async onCopySignerKey(e) {
      e.preventDefault();
      await copyTextToClipboard(this.clicksignSignerKey);
      bus.$emit('newToastMessage', this.$t('SCHOOL_CONTRACT_SETTINGS.CLICKSIGN_AUTOMATIC_SIGNATURE.COPY_SUCCESSFUL'));
    },
  }
};
</script>
<style lang="scss" scoped>
.school-settings--content {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: var(--space-small);

  .input {
    flex: 1;
    margin-right: var(--space-small);

    ::v-deep input {
      margin-bottom: 0;
    }
  }

  .disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
</style>
