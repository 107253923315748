import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import SchoolEnrollmentsApi from '../../api/kedu/schoolEnrollments';

export const state = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getSchoolEnrollments(_state) {
    return _state.records || [];
  },
  getEnrollment(_state) {
    return id => _state.records.find(enrollment => enrollment.id === id );
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  get: async function getEnrollments({ commit }, contactId) {
    commit(types.SET_SCHOOL_ENROLLMENT_UI_FLAG, { isFetching: true });
    try {
      const response = await SchoolEnrollmentsApi.get(contactId);
      commit(types.SET_SCHOOL_ENROLLMENTS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_SCHOOL_ENROLLMENT_UI_FLAG, { isFetching: false });
    }
  },

  create: async function createEnrollment({ commit }, {contactId, ...content}) {
    commit(types.SET_SCHOOL_ENROLLMENT_UI_FLAG, { isCreating: true });
    try {
      const response = await SchoolEnrollmentsApi.create(contactId, content);
      commit(types.ADD_SCHOOL_ENROLLMENT, response.data);
    } catch (error) {
      throw new Error(error, {cause: error?.response?.data});
    } finally {
      commit(types.SET_SCHOOL_ENROLLMENT_UI_FLAG, { isCreating: false });
    }
  },

  update: async function updateEnrollment({ commit }, { contactId, id, ...updateObj }) {
    commit(types.SET_SCHOOL_ENROLLMENT_UI_FLAG, { isUpdating: true });
    try {
      const response = await SchoolEnrollmentsApi.update(contactId, id, updateObj);
      commit(types.EDIT_SCHOOL_ENROLLMENT, response.data);
    } catch (error) {
      throw new Error(error, {cause: error?.response?.data?.errors});
    } finally {
      commit(types.SET_SCHOOL_ENROLLMENT_UI_FLAG, { isUpdating: false });
    }
  },

  addContractFile: async ({ commit }, { contactId, id, ...updateObj })  => {
    commit(types.SET_SCHOOL_ENROLLMENT_UI_FLAG, { isUpdating: true });

    let payload;
    if (updateObj.contractFile) {
      payload = new FormData();
      payload.append('contract_file', updateObj.contractFile);
    }

    try {
      const response = await SchoolEnrollmentsApi.addContractFile(contactId, id, payload);
      commit(types.EDIT_SCHOOL_ENROLLMENT, response.data);
    } catch (error) {
      throw new Error(error, {cause: error?.response?.data?.errors});
    } finally {
      commit(types.SET_SCHOOL_ENROLLMENT_UI_FLAG, { isUpdating: false });
    }
  },

  removeContractFile: async ({ commit }, { contactId, id,  contract_file_id })  => {
    commit(types.SET_SCHOOL_ENROLLMENT_UI_FLAG, { isUpdating: true });

    try {
      const response = await SchoolEnrollmentsApi.removeContractFile(contactId, id, contract_file_id);
      commit(types.EDIT_SCHOOL_ENROLLMENT, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SCHOOL_ENROLLMENT_UI_FLAG, { isUpdating: false });
    }
  },

  delete: async function deleteLabels({ commit }, {contactId, id}) {
    commit(types.SET_SCHOOL_ENROLLMENT_UI_FLAG, { isDeleting: true });
    try {
      await SchoolEnrollmentsApi.delete(contactId, id);
      commit(types.DELETE_SCHOOL_ENROLLMENT, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_SCHOOL_ENROLLMENT_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_SCHOOL_ENROLLMENT_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_SCHOOL_ENROLLMENTS]: MutationHelpers.set,
  [types.ADD_SCHOOL_ENROLLMENT]: MutationHelpers.create,
  [types.EDIT_SCHOOL_ENROLLMENT]: MutationHelpers.update,
  [types.DELETE_SCHOOL_ENROLLMENT]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
