/* global axios */
import ApiClient from './ApiClient';

class AccountAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  createAccount(data) {
    return axios.post(`${this.apiVersion}/accounts`, data);
  }

  searchForClasses(companyDocument, academicLevel, year) {
    return axios.get(`${this.baseUrl()}/search_for_classes`, {params: {company_document: companyDocument, academic_level: academicLevel, year: year}});
  }
}

export default new AccountAPI();
