/* global axios */
import ApiClient from '../ApiClient';

class KeduSchoolEnrollmentsApi extends ApiClient {
  constructor() {
    super('contacts', { accountScoped: true, kedu: true });
  }

  get url() {
    return `${this.baseUrl()}/contacts/${this.contactId}/school_enrollments`;
  }

  get(contactId) {
    this.contactId = contactId;
    return super.get();
  }

  create(contactId, content) {
    this.contactId = contactId;
    return super.create({ ...content });
  }

  update(contactId, id, content) {
    this.contactId = contactId;
    return super.update(id, content);;
  }

  addContractFile(contactId, id, content) {
    this.contactId = contactId;
    return axios.post(`${this.url}/${id}/add_contract_file`, content);
  }

  removeContractFile(contactId, id, contractFileId) {
    this.contactId = contactId;
    return axios.delete(`${this.url}/${id}/remove_contract_file`, {params: {contract_file_id: contractFileId}} ); 
  }

  searchForDependents(contactId, schoolDocument, responsibleFirstDocument) {
    this.contactId = contactId;
    return axios.get(`${this.url}/search_for_dependents`, {params: {school_document: schoolDocument, responsible_first_document: responsibleFirstDocument}});
  }

  searchForEnrollmentNumber(contactId, enrollmentNumber, schoolDocument) {
    this.contactId = contactId;
    return axios.get(`${this.url}/search_for_enrollment_number`, {params: {school_document: schoolDocument, enrollment_number: enrollmentNumber}});
  }

  searchOverdueBill(payload) {
    this.contactId = payload.contact_id;
    return axios.get(`${this.url}/search_overdue_bill`, {params: payload});
  }

  delete(contactId, id) {
    this.contactId = contactId;
    return super.delete(id);
  }
}

export default new KeduSchoolEnrollmentsApi();
