import { frontendURL } from '../../../../helper/URLHelper';

const reports = accountId => {
  const reports = {
    parentNav: 'reports',
    routes: [
      'account_overview_reports',
      'conversation_reports',
      'csat_reports',
      'pause_reports',
      'agent_reports',
      'school_enrollment_reports',
      'school_defaulter_reports',
      'label_reports',
      'label_funnel_reports',
      'inbox_reports',
      'team_reports',
      'campaign_reports',
    ],
    menuItems: [
      {
        icon: 'arrow-trending-lines',
        label: 'REPORTS_OVERVIEW',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/reports/overview`),
        toStateName: 'account_overview_reports',
      },
      {
        icon: 'chat',
        label: 'REPORTS_CONVERSATION',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/reports/conversation`),
        toStateName: 'conversation_reports',
      },
      {
        icon: 'emoji',
        label: 'CSAT',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/reports/csat`),
        toStateName: 'csat_reports',
      },
      {
        icon: 'pause-circle',
        label: 'REPORTS_PAUSE',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/reports/pauseReports`),
        toStateName: 'pause_reports',
      },
      {
        icon: 'people',
        label: 'REPORTS_AGENT',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/reports/agent`),
        toStateName: 'agent_reports',
      },
      {
        icon: 'tag',
        label: 'REPORTS_LABEL',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/reports/label`),
        toStateName: 'label_reports',
      },
      {
        icon: 'filter',
        label: 'REPORTS_LABEL_FUNNEL',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/reports/label_funnel`),
        toStateName: 'label_funnel_reports',
      },
      {
        icon: 'mail-inbox-all',
        label: 'REPORTS_INBOX',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/reports/inboxes`),
        toStateName: 'inbox_reports',
      },
      {
        icon: 'people-team',
        label: 'REPORTS_TEAM',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/reports/teams`),
        toStateName: 'team_reports',
      },
      {
        icon: 'megaphone',
        label: 'REPORTS_CAMPAIGN',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/reports/campaigns`),
        toStateName: 'campaign_reports',
      },
    ],
  }

  if (window.chatwootConfig.isKedu) {
    reports.menuItems.splice(5, 0,
      {
        icon: 'book-contacts',
        label: 'REPORTS_SCHOOL_ENROLLMENTS',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/reports/school_enrollment`),
        toStateName: 'school_enrollment_reports',
      },
      {
        icon: 'book-pulse',
        label: 'REPORTS_SCHOOL_DEFAULTER',
        hasSubMenu: false,
        toState: frontendURL(`accounts/${accountId}/reports/school_defaulter`),
        toStateName: 'school_defaulter_reports',
      }
    )
  }

  return reports;
};

export default reports;
