<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button color-scheme="success" class-names="button--fixed-top" icon="add-circle"
      @click="openSchoolClassModal()">
      {{ $t('SCHOOL_CLASSES.HEADER_BTN_TXT') }}
    </woot-button>
    <div class="flex flex-row gap-4">
      <div class="w-[60%]">
        <div>
          <div class="p-2">
            <label>
              {{ $t('SCHOOL_CLASSES.LIST.SCHOOL_ACCOUNT_SELECT.LABEL') }}
            </label>
            <multiselect v-model="selectedSchoolAccount" track-by="id" label="school_name"
              :placeholder="$t('SCHOOL_CLASSES.LIST.SCHOOL_ACCOUNT_SELECT.PLACEHOLDER')" selected-label
              :select-label="$t('SCHOOL_CLASSES.LIST.SCHOOL_ACCOUNT_SELECT.SELECT_PLACEHOLDER')"
              :deselect-label="$t('SCHOOL_CLASSES.LIST.SCHOOL_ACCOUNT_SELECT.REMOVE')" :max-height="160"
              :options="schoolAccounts" :allow-empty="false" :option-height="104" :preselect-first="true" />
          </div>

          <p v-if="!uiFlags.isFetching && !schoolClasses.length"
            class="flex h-full items-center flex-col justify-center">
            {{ $t('SCHOOL_CLASSES.LIST.404') }}
          </p>
          <woot-loading-state v-if="uiFlags.isFetching" :message="$t('SCHOOL_CLASSES.LOADING')" />

          <table v-if="!uiFlags.isFetching && schoolClasses.length" class="woot-table">
            <thead>
              <th v-for="(thHeader, index) in $t('SCHOOL_CLASSES.LIST.TABLE_HEADER')" :key="index">
                {{ thHeader }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(schoolClassRule, index) in schoolClasses" :key="index">
                <td>{{ schoolClassRule && schoolClassRule.school_class }}</td>
                <td>{{ schoolClassRule && schoolClassRule.schooling_type && $t('SCHOOL_CONTACT_FORM.FORM.STUDENT_SCHOOLING_TYPE.OPTIONS').find(option => option.VALUE === schoolClassRule.schooling_type).LABEL     }}</td>
                <td>{{ schoolClassRule && schoolClassRule.school_year }}</td>
                <td class="button-wrapper">
                  <woot-button v-tooltip.top="$t('SCHOOL_CLASSES.FORM.EDIT')" variant="smooth" size="tiny"
                    color-scheme="secondary" class-names="grey-btn" icon="edit"
                    @click="openSchoolClassModal(schoolClassRule)" />
                  <woot-button v-tooltip.top="$t('SCHOOL_CLASSES.FORM.DELETE')" variant="smooth" color-scheme="alert"
                    size="tiny" icon="dismiss-circle" class-names="grey-btn"
                    @click="openDeletePopup(schoolClassRule, index)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="w-[34%]">
        <span v-dompurify-html="$t('SCHOOL_CLASSES.SIDEBAR_TXT')" />
      </div>
    </div>

    <woot-delete-modal :show.sync="showDeleteConfirmationPopup" :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion" :title="$t('LABEL_MGMT.DELETE.CONFIRM.TITLE')"
      :message="$t('SCHOOL_CLASSES.DELETE.CONFIRM.MESSAGE')" :message-value="deleteMessage"
      :confirm-text="deleteConfirmText" :reject-text="deleteRejectText" />

    <woot-modal :show.sync="showSchoolClassModal" size="medium" :on-close="hideSchoolClassModal">
      <school-class-modal v-if="showSchoolClassModal" :on-close="hideSchoolClassModal"
        :school-class-rule="selectedSchoolClassRule" :school-account="selectedSchoolAccount" :on-submit="submitSchoolClass" />
    </woot-modal>

    <woot-confirm-modal ref="confirmDialog" :title="toggleModalTitle" :description="toggleModalDescription" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SchoolClassModal from './SchoolClassModal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import timeMixin from 'dashboard/mixins/time';

export default {
  components: {
    SchoolClassModal,
  },
  mixins: [alertMixin, timeMixin],

  data() {
    return {
      loading: {},
      showSchoolClassModal: false,
      showDeleteConfirmationPopup: false,
      selectedSchoolClassRule: {},
      selectedSchoolAccount: null,
      toggleModalTitle: this.$t('SCHOOL_CLASSES.TOGGLE.ACTIVATION_TITLE'),
      toggleModalDescription: this.$t(
        'SCHOOL_CLASSES.TOGGLE.ACTIVATION_DESCRIPTION'
      ),
    };
  },
  computed: {
    ...mapGetters({
      getAccount: 'accounts/getAccount',
      uiFlags: 'accounts/getUIFlags',
      accountId: 'getCurrentAccountId',
    }),
    schoolAccounts() {
      return this.getAccount(this.accountId)?.school_accounts || []
    },
    schoolClasses() {
      return this.schoolAccounts.find(schoolAccount => schoolAccount.id === this.selectedSchoolAccount?.id)?.school_class_rules || [];
    },
    deleteConfirmText() {
      return `${this.$t('SCHOOL_CLASSES.DELETE.CONFIRM.YES')} ${this.selectedSchoolClassRule?.school_class
        }`;
    },
    deleteRejectText() {
      return `${this.$t('SCHOOL_CLASSES.DELETE.CONFIRM.NO')} ${this.selectedSchoolClassRule?.school_class
        }`;
    },
    deleteMessage() {
      return ` ${this.selectedSchoolClassRule?.school_class}?`;
    },
  },

  methods: {
    openSchoolClassModal(response = null) {
      this.showSchoolClassModal = true;
      if (response) this.selectedSchoolClassRule = response;
      else this.selectedSchoolClassRule = null;
    },
    hideSchoolClassModal() {
      this.showSchoolClassModal = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedSchoolClassRule = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      this.deleteSchoolClass(this.selectedSchoolClassRule);
    },

    async deleteSchoolClass(selectedSchoolClassRule) {
      try {
        let payload = {
          school_accounts: [{
            ...this.selectedSchoolAccount,
            school_class_rules: [{...selectedSchoolClassRule, _destroy: true}]
          }]
        };

        await this.$store.dispatch('accounts/update', payload);
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'));
      }
    },

    async submitSchoolClass(selectedSchoolClass) {
      try {
        let payload = {
          school_accounts: [{
            ...this.selectedSchoolAccount,
            school_class_rules: [selectedSchoolClass]
          }]
        };

        await this.$store.dispatch('accounts/update', payload);
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.SUCCESS'));
      } catch (error) {
        this.showAlert(this.$t('GENERAL_SETTINGS.UPDATE.ERROR'));
      }
    },
  },
};
</script>
