<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box p-2">
      <woot-modal-header :header-title="$t('ASSIGN_CONTACT_LABEL.TITLE')">
        <p>
          {{ $t('ASSIGN_CONTACT_LABEL.DESC') }}
        </p>
      </woot-modal-header>
      <div class="modal-content overflow-auto">
        <div :class="{ error: $v.selectedLabel.$error }">
          <label>
            {{ $t('ASSIGN_CONTACT_LABEL.LABEL_SELECT.LABEL') }}
          </label>
          <multiselect v-model="selectedLabel" :options="labels" track-by="id" label="title"
            :placeholder="$t('ASSIGN_CONTACT_LABEL.LABEL_SELECT.PLACEHOLDER')" selected-label
            :select-label="$t('ASSIGN_CONTACT_LABEL.LABEL_SELECT.SELECT_PLACEHOLDER')" :allow-empty="true"
            :deselect-label="$t('ASSIGN_CONTACT_LABEL.LABEL_SELECT.REMOVE')" :max-height="160" :option-height="104" />
          <span v-if="$v.selectedLabel.$error" class="message">
            {{ $t('ASSIGN_CONTACT_LABEL.LABEL_SELECT.ERROR') }}
          </span>
        </div>
        <div class="mt-2">
          <div class="modal-footer">
            <div class="medium-12 columns">
              <woot-button :disabled="uiFlags.isUpdating || !selectedLabel" :loading="uiFlags.isUpdating"
                @click="assignContacts">
                {{ $t('ASSIGN_CONTACT_LABEL.SUBMIT_BUTTON') }}
              </woot-button>
              <button class="button clear" @click.prevent="onClose">
                {{ $t('ASSIGN_CONTACT_LABEL.CANCEL_BUTTON') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '../../../../components/Modal';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import alertMixin from 'shared/mixins/alertMixin';
import { CONTACTS_EVENTS } from '../../../../helper/AnalyticsHelper/events';
import configMixin from 'shared/mixins/configMixin';

export default {
  components: {
    Modal,
  },
  mixins: [alertMixin, configMixin],
  props: {
    onClose: {
      type: Function,
      default: () => { },
    },
    query: {
      type: Object,
      default: () => { }
    },
    queryLabel: {
      type: String,
      default: ''
    },
    querySearch: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      show: true,
      selectedLabel: null,
    };
  },
  validations: {
    selectedLabel: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      uiFlags: 'contacts/getUIFlags',
      labels: 'labels/getLabelsOnSidebar',
    }),
  },
  mounted() {
    this.$track(CONTACTS_EVENTS.EXPORT_MODAL_OPEN);
  },
  methods: {
    assignContacts() {
      const params = { queryFilter: this.query, queryLabel: this.queryLabel, querySearch: this.querySearch, label: this.selectedLabel.title };
      try {
        this.$store.dispatch('contactLabels/assignLabelToMultipleContacts', params);
        this.onClose();
      } catch (error) {
        this.showAlert(
          this.$t('ASSIGN_CONTACT_LABEL.SUBMIT_ERROR')
        );
      }
      
    },
  },
};
</script>