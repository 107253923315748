<template>
    <woot-modal :show.sync="show" :on-close="onClose" :showCloseButton="false" size="modal-small">
      <woot-modal-header
        :header-title="title"
        :header-content="message"
        :header-content-value="messageValue"
      />
      <div class="flex p-4">
        <woot-button
            v-for="(button, index) in buttonProps"
            :key="index"
            :color-scheme=button.color
            :variant=button.variant
            @click.prevent="button.onClick"
            >
            {{ button.text }}
        </woot-button>
      </div>
    </woot-modal >
  </template>
  
  <script>
  
  export default {

    props: {
      show: Boolean,
      onClose: { type: Function, default: () => {} },
      title: { type: String, default: '' },
      message: { type: String, default: '' },
      messageValue: { type: String, default: '' },
      buttonProps: {
        type: Array, default: () => []
      }
    },
  };
  </script>
  <style lang="scss" scoped>
  
  </style>
  