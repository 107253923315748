<template>
    <div class="flex-1 overflow-auto p-4">
      <div class="flex flex-row gap-4">
        <div class="w-[60%]">
          <div>
            <div class="p-2">
              <label>
                {{ $t('SCHOOL_CONTRACT_SETTINGS.SCHOOL_ACCOUNT_SELECT.LABEL') }}
              </label>
              <multiselect v-model="selectedSchoolAccount" track-by="id" label="school_name"
                :placeholder="$t('SCHOOL_CONTRACT_SETTINGS.SCHOOL_ACCOUNT_SELECT.PLACEHOLDER')" selected-label
                :select-label="$t('SCHOOL_CONTRACT_SETTINGS.SCHOOL_ACCOUNT_SELECT.SELECT_PLACEHOLDER')"
                :deselect-label="$t('SCHOOL_CONTRACT_SETTINGS.SCHOOL_ACCOUNT_SELECT.REMOVE')" :max-height="160"
                :options="schoolAccounts" :allow-empty="false" :option-height="104" :preselect-first="true" />
            </div>
  
            <p v-if="!uiFlags.isFetching && !schoolClicksignContracts.length"
              class="flex h-full items-center flex-col justify-center">
              {{ $t('SCHOOL_CONTRACT_SETTINGS.LIST.404') }}
            </p>

            <table v-if="!uiFlags.isFetching && schoolClicksignContracts.length" class="woot-table">
              <thead>
                <th v-for="(thHeader, index) in $t('SCHOOL_CONTRACT_SETTINGS.LIST.TABLE_HEADER')" :key="index">
                  {{ thHeader }}
                </th>
              </thead>
              <tbody>
                <tr v-for="(clicksignContractHook, index) in schoolClicksignContracts" :key="index">
                  <td>{{ clicksignContractHook.settings && clicksignContractHook.settings.name }}</td>
                  <td>{{ clicksignContractHook.settings && clicksignContractHook.settings.template_key}}</td>
                  <td class="button-wrapper">
                    <woot-button v-tooltip.top="$t('SCHOOL_CONTRACT_SETTINGS.FORM.EDIT')" variant="smooth" size="tiny"
                      color-scheme="secondary" class-names="grey-btn" icon="edit"
                      @click="openSchoolContractModal(clicksignContractHook)" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <div class="w-[34%]">
          <span v-dompurify-html="$t('SCHOOL_CONTRACT_SETTINGS.SIDEBAR_TXT')" />
        </div>
      </div>

      <woot-modal :show.sync="showSchoolContractModal" size="medium" :on-close="hideSchoolContractModal">
        <school-contract-modal v-if="showSchoolContractModal" :on-close="hideSchoolContractModal"
          :school-contract-settings="clicksignHook" />
      </woot-modal>
    </div>
  </template>
  <script>
  import { mapGetters } from 'vuex';
  import SchoolContractModal from './SchoolContractModal.vue';
  import alertMixin from 'shared/mixins/alertMixin';
  import timeMixin from 'dashboard/mixins/time';
  
  export default {
    components: {
      SchoolContractModal,
    },
    mixins: [alertMixin, timeMixin],
  
    data() {
      return {
        loading: {},
        showSchoolContractModal: false,
        selectedSchoolContractHook: {},
        selectedSchoolAccount: null,
      };
    },
    computed: {
      ...mapGetters({
        getAccount: 'accounts/getAccount',
        uiFlags: 'accounts/getUIFlags',
        accountId: 'getCurrentAccountId',
      }),
      schoolAccounts() {
        return this.getAccount(this.accountId)?.school_accounts || []
      },
      clicksignAccountIntegrations() {
        return this.$store.getters['integrations/getIntegration']('clicksign');
      },
      schoolClicksignContracts() {
        return this.clicksignAccountIntegrations.hooks.filter(hook => !hook?.settings?.school_account_id || (hook?.settings?.school_account_id === `${this.selectedSchoolAccount?.id}`)) || []
      },
      clicksignHook() {
        return this.selectedSchoolContractHook && this.$store.getters['integrations/getIntegration']('clicksign').hooks.find(hook => hook.id === this.selectedSchoolContractHook.id)
      }
    },
  
    methods: {
      openSchoolContractModal(response = null) {
        this.showSchoolContractModal = true;
        if (response) this.selectedSchoolContractHook = response;
        else this.selectedSchoolContractHook = null;
      },
      hideSchoolContractModal() {
        this.showSchoolContractModal = false;
      },
    },
  };
  </script>
  