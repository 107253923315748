<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="arrow-download"
      @click="openDownloadModal"
    >
      {{ $t('SCHOOL_ENROLLMENT_REPORTS.DOWNLOAD_REPORTS') }}
    </woot-button>
    <report-filters
      v-if="filterItemsList"
      type="agent"
      :filter-items-list="filterItemsList"
      :group-by-filter-items-list="groupByfilterItemsList"
      :selected-group-by-filter="selectedGroupByFilter"
      @date-range-change="onDateRangeChange"
      @filter-change="onFilterChange"
      @group-by-filter-change="onGroupByFilterChange"
      :disable-business-hour="true"
    />
    <report-container v-if="filterItemsList.length" :group-by="groupBy" :reportKeys="reportKeys"/>
    <woot-modal :show.sync="downloadModalOpen" :on-close="onCloseDownloadModal">
      <download-report-modal v-if="downloadModalOpen" :title="$t('SCHOOL_ENROLLMENT_REPORTS.DOWNLOAD_REPORTS')" :show="downloadModalOpen" :on-close="onCloseDownloadModal" :on-choose="downloadReports" />
    </woot-modal>
  </div>
</template>

<script>
import ReportFilters from './components/ReportFilters';
import ReportContainer from './ReportContainer.vue';
import { GROUP_BY_FILTER } from './constants';
import reportMixin from './../../../../mixins/reportMixin';
import { generateFileName } from './../../../../helper/downloadHelper';
import { REPORTS_EVENTS } from './../../../../helper/AnalyticsHelper/events';
import DownloadReportModal from './components/DownloadReportModal.vue';

const REPORTS_KEYS = {
  SCHOOL_ENROLLMENTS: 'school_enrollments_count'
};

export default {
  components: {
    ReportFilters,
    ReportContainer,
    DownloadReportModal,
  },

  mixins: [reportMixin],

  data() {
    return {
      from: 0,
      to: 0,
      selectedFilter: null,
      groupBy: GROUP_BY_FILTER[1],
      groupByfilterItemsList: this.$t('REPORT.GROUP_BY_DAY_OPTIONS'),
      selectedGroupByFilter: null,
      downloadModalOpen: false,
    };
  },
  computed: {
    reportKeys() {
      return [
        'SCHOOL_ENROLLMENTS',
      ];
    },
    filterItemsList() {
      return [{name: this.$t('SCHOOL_ENROLLMENT_REPORTS.ALL_AGENTS_OPTION_LABEL'), id: null}, ...this.$store.getters['agents/getAgents']] || [];
    },
  },
  mounted() {
    this.$store.dispatch('agents/get');
  },
  methods: {
    fetchAllData() {
      if (this.selectedFilter) {
        const { from, to, groupBy } = this;
        this.$store.dispatch('fetchAccountSummary', {
          from,
          to,
          type: 'school_enrollment',
          id: this.selectedFilter.id,
          groupBy: groupBy.period,

        });
        this.fetchChartData();
      }
    },
    fetchChartData() {
      this.reportKeys.forEach(async key => {
        try {
          const { from, to, groupBy } = this;
          this.$store.dispatch('fetchAccountReport', {
            metric: REPORTS_KEYS[key],
            from,
            to,
            type: 'school_enrollment',
            id: this.selectedFilter.id,
            groupBy: groupBy.period,
          });
        } catch {
          this.showAlert(this.$t('REPORT.DATA_FETCHING_FAILED'));
        }
      });
    },
    openDownloadModal(){
      this.downloadModalOpen = true
    },
    onCloseDownloadModal(){
      this.downloadModalOpen = false
    },
    downloadReports(fileType) {
      const { from, to } = this;
      const fileName = generateFileName({ type: 'school-enrollments', to, fileType});
      const params = { from, to, fileName, fileType};
      if (this.selectedFilter && this.selectedFilter.id) {
        params.id = this.selectedFilter.id;
      }
      this.$store.dispatch('downloadEnrollmentsPerAgentReports', params);
    },
    onDateRangeChange({ from, to, groupBy }) {
      // do not track filter change on inital load
      if (this.from !== 0 && this.to !== 0) {
        this.$track(REPORTS_EVENTS.FILTER_REPORT, {
          filterType: 'date',
          reportType: 'school_enrollment',
        });
      }

      this.from = from;
      this.to = to;
      this.groupByfilterItemsList = this.fetchFilterItems(groupBy);
      const filterItems = this.groupByfilterItemsList.filter(
        item => item.id === this.groupBy.id
      );
      if (filterItems.length > 0) {
        this.selectedGroupByFilter = filterItems[0];
      } else {
        this.selectedGroupByFilter = this.groupByfilterItemsList[0];
        this.groupBy = GROUP_BY_FILTER[this.selectedGroupByFilter.id];
      }
      this.fetchAllData();
    },
    onFilterChange(payload) {
      if (payload) {
        this.selectedFilter = payload;
        this.fetchAllData();
      }
    },
    onGroupByFilterChange(payload) {
      this.groupBy = GROUP_BY_FILTER[payload.id];
      this.fetchAllData();

      this.$track(REPORTS_EVENTS.FILTER_REPORT, {
        filterType: 'groupBy',
        filterValue: this.groupBy?.period,
        reportType: 'school_enrollment',
      });
    },
    fetchFilterItems(groupBy) {
      switch (groupBy) {
        case GROUP_BY_FILTER[2].period:
          return this.$t('REPORT.GROUP_BY_WEEK_OPTIONS');
        case GROUP_BY_FILTER[3].period:
          return this.$t('REPORT.GROUP_BY_MONTH_OPTIONS');
        case GROUP_BY_FILTER[4].period:
          return this.$t('REPORT.GROUP_BY_YEAR_OPTIONS');
        default:
          return this.$t('REPORT.GROUP_BY_DAY_OPTIONS');
      }
    },
  },
};
</script>
