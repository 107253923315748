/* global axios */
import ApiClient from './ApiClient';

const getTimeOffset = () => -new Date().getTimezoneOffset() / 60;

class ReportsAPI extends ApiClient {
  constructor() {
    super('reports', { accountScoped: true, apiVersion: 'v2' });
  }
  
  getReports({
    metric,
    from,
    to,
    type = 'account',
    id,
    groupBy,
    businessHours,
    taggableType
  }) {
    return axios.get(`${this.url}`, {
      params: {
        metric,
        since: from,
        until: to,
        type,
        id,
        group_by: groupBy,
        business_hours: businessHours,
        timezone_offset: getTimeOffset(),
        taggable_type: taggableType
      },
    });
  }

  getSummary(since, until, type = 'account', id, groupBy, businessHours) {
    return axios.get(`${this.url}/summary`, {
      params: {
        since,
        until,
        type,
        id,
        group_by: groupBy,
        business_hours: businessHours,
        timezone_offset: getTimeOffset(),
      },
    });
  }

  getConversationMetric(type = 'account', page = 1) {
    return axios.get(`${this.url}/conversations`, {
      params: {
        type,
        page,
      },
    });
  }

  getAgentReports({ from: since, to: until, businessHours, fileType }) {
    return axios.get(`${this.url}/agents`, {
      headers: {Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
      params: { since, until, business_hours: businessHours, file_type: fileType },
      responseType: "blob"
    });
  }

  getConversationTrafficCSV() {
    return axios.get(`${this.url}/conversation_traffic`, {
      params: { timezone_offset: getTimeOffset() },
    });
  }

  getLabelReports({ from: since, to: until, businessHours, fileType }) {
    return axios.get(`${this.url}/labels`, {
      headers: {Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
      params: { since, until, business_hours: businessHours, file_type: fileType },
      responseType: "blob"
    });
  }

  getCampaignReports({ from: since, to: until, businessHours, fileType }) {
    return axios.get(`${this.url}/campaigns`, {
      headers: {Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
      params: { since, until, business_hours: businessHours, file_type: fileType },
      responseType: "blob"
    });
  }

  getInboxReports({ from: since, to: until, businessHours, fileType }) {
    return axios.get(`${this.url}/inboxes`, {
      headers: {Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
      params: { since, until, business_hours: businessHours, file_type: fileType },
      responseType: "blob"
    });
  }

  getTeamReports({ from: since, to: until, businessHours, fileType }) {
    return axios.get(`${this.url}/teams`, {
      headers: {Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
      params: { since, until, business_hours:businessHours, file_type: fileType },
      responseType: "blob"
    });
  }

  getContactsReportsCSV({ queryFilter, label }) {
    return axios.post(`${this.url}/contacts_csv`, {
      queryFilter,
      label
    });
  }

  getContactsReportsXLSX({queryFilter, label}) {
    const headers = {"Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"};
    const responseType = "blob";
    const body = { queryFilter, label };
    const url = `${this.url}/contacts_xlsx`;

    return axios.post(url, body, {headers, responseType});
  }

  getEnrollmentsReportsCSV({ queryFilter, label }) {
    return axios.post(`${this.url}/school_enrollments_csv`, {
      queryFilter,
      label
    });
  }

  getEnrollmentsReportsXLSX({queryFilter, label}) {
    const headers = {"Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"};
    const responseType = "blob";
    const body = { queryFilter, label }
    const url = `${this.url}/school_enrollments_xlsx`

    return axios.post(url, body, {headers, responseType});
  }

  getEnrollmentsPerAgentReports({ from: since, to: until, businessHours, fileType, id }) {
    return axios.get(`${this.url}/school_enrollments_per_agent`, {
      headers: {Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
      params: { since, until, business_hours: businessHours, file_type: fileType, user_id: id },
      responseType: "blob"
    });
  }

  getSchoolDefaultersPerAgentReports({ from: since, to: until, businessHours, fileType, id }) {
    return axios.get(`${this.url}/school_defaulters_per_agent`, {
      headers: {Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
      params: { since, until, business_hours: businessHours, file_type: fileType, user_id: id },
      responseType: "blob"
    });
  }
}

export default new ReportsAPI();
